.btn--icon {
  color: scale-color($primary, $lightness: +90%, $saturation: -10%);

  &:hover {
    color: $white;
  }
}

.btn-lang-switch.btn {
  border-radius: 0;
  background-color: $white;
}

.btn.btn-primary,
.btn.btn-secondary {
  @include brand-border-radius(8px);
}

.btn-group {
  .btn {
    border-radius: 0;
  }
}

.btn:disabled,
.btn.btn-outline-secondary.disabled {
  background-color: $disabled-bg-grey;
  opacity: 1;
  color: $disabled-text-grey;
  border-color: $disabled-border-grey;
}

.btn-chevron {
  @include brand-chevron-link($primary);
  background-color: $primary;
  background-clip: content-box;
  color: white;

  &:hover,
  &:active {
    color: white !important;
    @include brand-chevron-link(scale-color($primary, $lightness: -15%));
  }
  &:disabled {
    border: 0px;
    background-color: white;
    @include brand-chevron-link($disabled-bg-grey);
    &::before,
    &::after {
      border: $disabled-border-grey 1px solid;
    }
    &::before {
      border-bottom: 0px;
    }
    &::after {
      border-top: 0px;
    }
  }
}

.btn-chevron-navy {
  @include brand-chevron-link($navy);
  &:hover,
  &:active {
    color: white !important;
    @include brand-chevron-link(scale-color($navy, $lightness: -15%));
  }
}

.btn-chevron-amber {
  @include brand-chevron-link($amber);
  color: black;
}

.btn-chevron-green {
  @include brand-chevron-link($green);
  color: black;
}

.context-aware-toggle-btn {
  &__text {
    min-width: 3rem;
  }
}

.notes-button:focus-visible {
  @include keyboard-focus-visible;
}

.btn-circle-primary {
  padding: 0;
  svg {
    font-size: 3rem;
    color: $primary;
  }

  &:disabled {
    background-color: white;
    border: none;
    svg {
      color: $disabled-border-grey;
    }
  }

  &:active {
    border: none;
  }

  &:hover {
    svg {
      color: scale-color($primary, $lightness: -15%);
    }
  }

  &:focus-visible {
    @include keyboard-focus-visible;
  }
}
