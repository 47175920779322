.sp-checkbox-wrapper {
  --s-xsmall: 0.625em;
  --s-small: 1.2em;
  --border-width: 1px;
  --c-primary: #009612;
  --c-primary-20-percent-opacity: rgb(0 150 18/ 20%);
  --c-primary-10-percent-opacity: rgb(0 150 18 / 10%);
  --t-base: 0.4s;
  --t-fast: 0.2s;
  --e-in: ease-in;
  --e-out: cubic-bezier(0.11, 0.29, 0.18, 0.98);
}

.sp-checkbox-wrapper .visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sp-checkbox-wrapper > *:hover {
  cursor: pointer;
}

.sp-checkbox-wrapper .sp-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sp-checkbox-wrapper .sp-checkbox + .sp-checkbox {
  margin-top: var(--s-small);
}
.sp-checkbox-wrapper .sp-checkbox__symbol {
  display: inline-block;
  display: flex;
  margin-right: calc(var(--s-small) * 0.7);
  border: var(--border-width) solid $green;
  position: relative;
  border-radius: 0.1em;
  width: 1.5em;
  height: 1.5em;
  transition: box-shadow var(--t-base) var(--e-out),
    background-color var(--t-base);
  box-shadow: 0 0 0 0 var(--c-primary-10-percent-opacity);
}
.sp-checkbox-wrapper .sp-checkbox__symbol:after {
  content: '';
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 0.25em;
  height: 0.25em;
  background-color: var(--c-primary-20-percent-opacity);
  opacity: 0;
  border-radius: 3em;
  transform: scale(1);
  transform-origin: 50% 50%;
}
.sp-checkbox-wrapper .sp-checkbox .icon-checkbox {
  width: 1em;
  height: 1em;
  margin: auto;
  fill: none;
  stroke-width: 5;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  color: $green;
  display: inline-block;
}
.sp-checkbox-wrapper .sp-checkbox .icon-checkbox path {
  transition: stroke-dashoffset var(--t-fast) var(--e-in);
  stroke-dasharray: 30px, 31px;
  stroke-dashoffset: 31px;
}
.sp-checkbox-wrapper .sp-checkbox__textwrapper {
  margin: 0;
}

// .sp-checkbox-wrapper
//   .sp-checkbox__trigger:checked
//   + .sp-checkbox__symbol:after {
//   -webkit-animation: ripple-33 1s var(--e-out);
//   animation: ripple-33 1s var(--e-out);
// }
.sp-checkbox-wrapper
  .sp-checkbox__trigger:checked
  + .sp-checkbox__symbol
  .icon-checkbox
  path {
  transition: stroke-dashoffset var(--t-base) var(--e-out);
  stroke-dashoffset: 0px;
}
.sp-checkbox-wrapper .sp-checkbox__trigger:focus + .sp-checkbox__symbol {
  box-shadow: 0 0 0 0.25em var(--c-primary-20-percent-opacity);
}

@-webkit-keyframes ripple-33 {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(20);
  }
}

@keyframes ripple-33 {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(20);
  }
}
