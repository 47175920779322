.header {
  background-color: $navy;
  background-image: url('../../assets/images/SP2-header-sm.png');
  background-position: center;
  background-size: cover;
  color: $white;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 5px solid $primary;

  &.public-header {
    background-image: none;
    border-bottom: 0;

    #language-switch-dropdown {
      @extend .btn-lg;
      background: none;
      border: 0;

      &:hover,
      &:focus {
        background: $primary;
      }
    }

    .about-us {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  // using an ID as the DropdownButton does not accept classnames as props
  #user-dropdown {
    text-align: left;
    padding: 0;

    &.dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }

  .profile-info {
    display: none;
  }

  @include media-breakpoint-up(sm) {
    background-image: url('../../assets/images/SP2-header-md.png');

    .list-unstyled li {
      padding: 0;
    }

    .profile-info {
      display: flex;
    }
  }

  @include media-breakpoint-up(md) {
    background-image: url('../../assets/images/SP2-header-lg.png');
  }

  @include media-breakpoint-up(lg) {
    background-image: url('../../assets/images/SP2-header-xl.png');
  }
}

.offcanvas-body {
  .list-unstyled li {
    padding: 0.5rem;
  }
}

.offcanvas-header {
  .logo {
    max-width: 250px;
  }
}

@media screen and (orientation: landscape) and (min-device-height: 319px) and (max-device-height: 480px) {
  .header {
    padding-top: 0px;
    padding-bottom: 0px;

    .navbar {
      padding-top: 0px;
      padding-bottom: 0px;

      .logo svg {
        height: 30px;
      }
    }
  }
}