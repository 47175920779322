.role-card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    outline: 2px solid $primary;
    aspect-ratio: 1 / 1;
    max-width: 12rem;

    img {
        height: 100%;
    }
}