.nav-user-info {
  background-color: $white;
  @include brand-border-radius(8px)
}

@include media-breakpoint-up(sm) {
  .nav-user-info {
    background-color: $white;
    max-width: 50vw;
    min-width: 25vw;
  }
}
