.w-175rem {
  width: 1.75rem;
}

.h-175rem {
  height: 1.75rem;
}

.w-10rem {
  width: 10rem;
}

.mw-10rem {
  min-width: 10rem;
}
