// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'https://cdn.example.com/assets/';
$base-url: '/assets/' !default;

/// Brand Primary Colour
/// @type Color
$primary: #e40a46;
// WCAG AA:Pass with Contrast Ratio 4.5:1 against $bg-grey #E6E6E6
$primary-dark: #ce0940;
// Used in launch event holding image
$primary-light: #ea355b;

/// Brand Secondary Colour
/// @type Color
$secondary: #333333;

/// Brand Warning Colour
/// https://webaim.org/resources/contrastchecker/?fcolor=EB0C00&bcolor=FFFFFF
/// Contrast Ratio
// 4.58:1
/// @type Color
$danger: #eb0c00;
$success: #025234;

/// Base Font
/// @type String
$font-family-sans-serif: 'Raleway', Roboto, 'Helvetica Neue', 'Noto Sans',
  'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji' !default;

$bg-grey: #f6f6f6;
$black: #000000;
$dark-grey: #848484;
$navy: #214774;
$green: #048956;
$amber: #e4b20a;
$disabled-bg-grey: #ececec;
$disabled-text-grey: #6b6b6b;
$disabled-border-grey: #bdbdbd;
$enable-rounded: false;

$h1-font-size: 1.7rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.3rem;
$h4-font-size: 1.2rem;
$h5-font-size: 1.1rem;

$link-color: $navy;

$card-cap-bg: #ffffff;

// React big calendar variable overrrides
$event-bg: $navy;
$event-border: darken($navy, 10%) !default;
$event-outline: $black;

$warning: $amber;
$info: $navy;
