.stage-progress {
  &::before {
    content: '';
    position: absolute;
    width: 99%;
    height: 2px;
    top: 0.75rem;
    background-color: $secondary;
  }

  &__marker {
    height: 1.5rem;
    width: 1.5rem;
  }

  &__current {
    position: absolute;
    height: 2px;
    top: 0.75rem;
    background-color: $primary;
  }
}
