.fade-out-highlight {
  animation: fadeOut 5s;
}

.fade-out-action {
  animation: fadeOutAction 0.5s;
}

@keyframes fadeOut {
  0% {
    background-color: scale-color($amber, $lightness: +30%, $saturation: -10%);
  }
  100% {
    background-color: white;
  }
}

@keyframes fadeOutAction {
  0% {
    background-color: scale-color(
      $secondary,
      $lightness: +30%,
      $saturation: -10%
    );
  }
  100% {
    background-color: white;
  }
}

.transition {
  -webkit-transition: transform 0.4s; /* Safari */
  transition: transform 0.4s;
}
