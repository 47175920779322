.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.admin-home {
  display: flex;
  overflow: clip;
  position: relative;
  width: 100%;
}

.admin-main {
  display: flex;
  flex-direction: column;
  min-width: 0;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out,
    margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
  width: 100%;
}

.breadcrumb {
  width: fit-content;
  @include brand-border-radius(8px);
  background-color: white;
  padding: 0.5rem 1rem;
}
