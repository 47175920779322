.attendance-percentage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  font-weight: 900;
  font-size: 1.75rem;
  outline: 2px solid $primary;
  border: 2px solid $white;
  aspect-ratio: 1 / 1;
}
