.progress-circle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        transform: rotate(-90deg);
    }

    &__separators {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .line-container {
            position: absolute;
            height: 80%;

            .line {
                background: white;
                width: 2px;
                height: 10%;
            }
        }
    }

    &__text {
        position: absolute;
        text-align: center;
        p {
            margin-bottom: 0px;
            width: min-content;
        }
        span {
            font-size: 52px;
            color: $primary;
            font-weight: bold;
            line-height: 2rem;
        }
    }
}