.loading > * {
  --bs-text-opacity: 1;
  color: #6c757d !important;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  pointer-events: none;
}

.loading-card {
  width: 100%;
  @include loading-shimmer;
}
