.card {
  @include brand-shadow;
  border: 0px;
  @include brand-border-radius-top-right(2rem);

  .card-header {
    @include brand-border-radius-top-right(2rem);
    padding-right: 1.5rem;
  }

  margin-bottom: 0.5rem;
}

.card-header {
  border-bottom: 1px solid $disabled-bg-grey;
}

.snake-ol {
  .card{
    box-shadow: none;
    
  }
}