.roles-list,
.roles-list-vertical {
  flex-direction: column;
  position: relative;
  width: 75%;

  .card {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .card-header {
    padding-right: 1rem !important;
  }

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: $primary;
    width: 50px;
    top: 0;
    left: calc(50% - 25px);
  }

  .role-card-icon {
    margin: 0 auto;
    margin-top: 2rem;
  }
}

@include media-breakpoint-up(md) {
  .roles-list {
    flex-wrap: nowrap;
    flex-direction: row;

    .card {
      flex: 1 1 0px;
    }

    &:before {
      height: 50px;
      width: 100vw;
      top: calc(50% - 25px);
      left: auto;
    }
  }
}

@media screen and (orientation: landscape) and (min-device-height: 319px) and (max-device-height: 480px) {
  .role-welcome-message {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .role-select-container {
    max-width: none;
  }
  .roles-list {
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: row;
    .card {
      flex: 1 1 0px;
      .card-header {
        display: flex;
        align-items: center;
        padding-right: 0px !important;
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .card-body {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
    .role-card-icon {
      width: 5rem;
    }
    &:before {
      height: 50px;
      width: 80vw;
      top: calc(50% - 25px);
      left: auto;
    }
    .role-card-icon {
      height: 5rem;
      margin-top: 0;
      margin-right: 1rem;
      padding: 0.5rem;
    }
  }
}
