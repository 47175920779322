.cookie-banner {
    position: fixed;
    bottom: 0px;
    z-index: 99999;
    margin-bottom: 0;
    width: 100%;

    .btn-group {
        display: flex;
        flex-wrap: wrap;
    }

    @include media-breakpoint-up(lg) {
        padding: 1rem 15rem;
        .btn-group {
            display: inline-flex;
            // float: right;
        }
    }
}
