h1 {
  font-weight: bold;
}

h2 {
  font-weight: bold;
}

h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.thead-responsive {
  @include media-breakpoint-down(md) {
    font-size: rfs-fluid-value(0.875rem);
  }
}
