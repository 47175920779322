// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Kitty Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/// @param {String} $color
@mixin darken($color) {
  filter: brightness(90%);
  background-color: $color;
  border-color: $color;
}

/// @param {String} $pixels
@mixin brand-border-radius($pixels) {
  border-radius: $pixels $pixels $pixels 0px;
}

/// @param {String} $rem
@mixin brand-border-radius-top-right($rem) {
  border-radius: 0 $rem 0 0;
}

/// @param {String} $background-color
@mixin brand-chevron-link($background-color) {
  $button-border-width: 1px;
  $button-chevron-skew: 20deg;
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
    // border: $button-border-width solid $black;
    background-color: $background-color;
  }

  &:before {
    top: -$button-border-width;
    bottom: 50%;
    border-bottom: none;
    -webkit-transform: skewX($button-chevron-skew);
  }

  &:after {
    top: 48%;
    bottom: -#{$button-border-width};
    border-top: none;
    -webkit-transform: skewX(-$button-chevron-skew);
  }
}

@mixin loading-shimmer {
  animation: skeleton-loading 1s linear infinite alternate;

  div {
    display: none;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: scale-color(
      $dark-grey,
      $lightness: +65%,
      $saturation: -10%
    );
    color: scale-color($dark-grey, $lightness: +65%, $saturation: -10%);
  }

  100% {
    background-color: scale-color(
      $dark-grey,
      $lightness: +95%,
      $saturation: -10%
    );
    color: scale-color($dark-grey, $lightness: +95%, $saturation: -10%);
  }
}

.cursor-pointer {
  cursor: pointer;
}

@mixin brand-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

@mixin keyboard-focus-visible {
    outline: 9px double black;
    box-shadow: 0 0 0 6px white;
}
