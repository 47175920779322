.chevrons {
  background-image: url('../../../assets/images/final-1920x1080.png');
  background-repeat: repeat-y;
}

.launch-event-container {
  position: relative;
}

.launch-event-coming-soon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: $primary-light;
}

.qanda-container {
  min-height: 300px;

  iframe {
    width: 100%;
    min-height: 300px;
  }
}

.launch-event-coming-soon img {
  width: 100%;
  height: auto;
}
