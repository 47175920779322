.alert-success {
  background-color: white;
  border-width: 5px;
  border-color: $success;
  color: black;
}

.alert-danger {
  background-color: white;
  border-width: 5px;
  border-color: $danger;
  color: black;
}

.alert-warning {
  background-color: white;
  border-width: 5px;
  border-color: $warning;
  color: black;
}

.alert-info {
  background-color: white;
  border-width: 5px;
  border-color: $info;
  color: black;
}
