$shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
$gray-border: darken(#f8f8f8, 10%);
$gray-light: rgba(0, 0, 0, 0.35);

.card-list-table {
  table-layout: fixed;
  background: transparent;
  margin-bottom: 0;
  width: 100%;

  thead {
    display: none;
    vertical-align: top;

    .form-control,
    .select-input,
    .clear-filter {
      margin-top: 5px;
    }
  }

  tbody tr {
    background: #fff;
    border: 1px solid $gray-border;
    display: block;
    padding: 5px 10px;
    margin: 0 0 10px 0;
    box-shadow: $shadow;
  }

  tbody td {
    border: 0;
    display: block;
    padding: 5px 5px 10px 30%;
    position: relative;

    &:first-of-type::after {
      visibility: hidden;
    }

    &:after {
      content: '';
      width: 100%;
      display: block;
      margin: 0 auto;
      height: 1px;
      background: $gray-border;
      position: absolute;
      left: 0;
      right: 0;
      top: -0.25rem;
    }

    &:before {
      color: $black;
      font-size: 0.85em;
      content: attr(data-title);
      display: table-cell;
      font-weight: 700;
      height: 100%;
      left: 0;
      margin: auto;
      position: absolute;
      vertical-align: middle;
      max-width: 35%;
    }

    &:last-child {
      display: flex;
      justify-content: center;
      padding: 10px 0px 20px 0px;
    }
  }
}

.sorting-button {
  margin-top: -5px;

  &:focus-visible {
    @include keyboard-focus-visible;
  }
}

th > div > div.select-input,
th > div.select-input,
th .select-input {
  font-weight: normal;
}

.table-offcanvas-btn{
  display: inline-block;
}

@include media-breakpoint-up(md) {
  .card-list-table {
    background: white;

    tbody tr {
      background: transparent;
      box-shadow: none;
      margin: 0;
      border: none;

      &:nth-of-type(even) {
        background: $gray-border;
      }
    }

    thead {
      display: table-header-group;

      th:last-child {
        box-shadow: none;
      }

      th {
        border-bottom: 1px solid black;
        padding: 0.25rem 1rem;
      }
    }

    tbody {
      tr {
        display: table-row;
        padding-bottom: 0;

        &:nth-of-type(even) {
          background: #fff;
        }
      }

      td {
        border-bottom: 1px solid $gray-border;
        display: table-cell;
        padding: 0.5rem 1rem;
        transition: background 0.2s ease-out;
        vertical-align: middle;

        &:after {
          display: none;
        }

        &:before {
          content: '';
        }
        &:last-child {
          display: table-cell;
          padding: 0.5rem 1rem;
        }
      }
    }
  }

  .table-offcanvas-btn {
    display: none;
  }
}

.pagination-footer {
  display: flex;
  position: relative;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: auto;
  padding-top: 2rem;
  flex-wrap: wrap;

  .select-input,
  .form-control,
  .paginate-label {
    margin-bottom: 16px;
  }

  .form-control {
    width: 4rem;
  }

  .text-black {
    .page-link {
      color: black;
    }
  }
}

.refetching-table-cell {
  @include loading-shimmer;

  div {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}
