.sidebar {
  background: transparent;
  border-right: 0;
  max-width: 250px;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out,
    margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
  z-index: 1;
  @include brand-shadow;
}

.sidebar-content {
  height: 100%;
  min-height: 100%;
  transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out,
    margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
}

.sidebar-nav {
  flex: 1;
  justify-content: flex-start;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.sidebar-content {
  background: #fff;
  display: flex;
  flex-direction: row;
}

.sidebar-nav {
  background: #fff;
  display: flex;
  flex-direction: column;
}

.sidebar-item {
  &__link {
    color: $black;
    text-decoration: none;
    .sidebar-item__icon {
      color: $dark-grey;
    }

    &--offline {
      background-color: $disabled-bg-grey;
      color: $disabled-text-grey;

      .sidebar-item__icon {
        color: $disabled-text-grey;
      }
    }
  }
  &__link.active {
    @include brand-chevron-link($bg-grey);
    border: none;
    .sidebar-item__icon {
      color: $primary;
    }
  }

  &__link:hover {
    background-color: $bg-grey;
    color: black;
    svg {
      filter: brightness(60%);
    }
  }
}
