.form-control[aria-invalid='true'],
.form-control.is-invalid {
  border-color: $danger;
  border-width: 2px;
}

.form-control:focus {
  border-color: $navy;
  box-shadow: 0 0 0 0.25rem rgba(33, 71, 116, 0.25);
}
