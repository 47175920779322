// .navbar-nav .dropdown-menu {
//   position: absolute;
// }

.navbar-nav .show > .nav-link {
  color: $white;
}

.nav-secondary .nav-item {
  color: $black;
  .active {
    background-color: $primary;
    color: $white;
    .nav-item-description {
      color: $white;
    }
  }
  .nav-item-description {
    color: $dark-grey;
  }
}
