.stars {
  color: $amber;
}

.stars {
  display: flex;
}

.stars.vertical {
  flex-direction: column-reverse;
  align-items: center;
}

.stars input {
  // position: absolute;
  // left: -100vw;
  width: 1px;
  height: 1px ;
  position: absolute;
  appearance: none;
  &:focus-visible {
    margin-top: 25px;
    margin-left: 25px;
    outline-offset: 25px;
    @include keyboard-focus-visible;
  }
}

.snake-ol {
  padding-left: 0;
  list-style-type: none;

  .accordion-item,
  .snake-li {
    border: 0px;
    &:last-child {
      .list-item-line::before {
        content: none;
      }
    }
  }
}

.list-item {
  display: flex;
  flex-direction: row;

  &-snake {
    display: flex;
    flex-direction: column;
    transform: translateY(20px);
  }

  &-image {
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    border: 3px solid $primary;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-line {
    position: relative;
    height: 100%;
    flex: 1 1 auto;

    &:before {
      content: '';
      width: 3px;
      height: 100%;
      background-color: $primary;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-other {
    padding: 20px;
    border-bottom: 1px solid gray;
  }
}

.objectives-overview-table {
  .list-item-image {
    width: 30px;
    height: 30px;
    border-width: 2px;
    background-color: white;
  }

  .list-item-line::before {
    width: 100%;
    height: 2px;
    top: 15px;
    transform: translateX(15px);
  }

    th:last-child {
      .list-item-line::before {
        content: none;
      }
    }
}

.stars .star__input-wrapper {
  &:first-of-type {
    padding-left: 0;
  }
}

// default stars are filled
.stars .star__input-wrapper label {
  width: 48px;
  height: 48px;
  padding: 48px 0 0;
  overflow: hidden;
  background-color: $amber;
  -webkit-mask: url('../icons/star-solid.svg') no-repeat 50% 50%;
  mask: url('../icons/star-solid.svg') no-repeat 50% 50%;
}

.stars .star__input-wrapper.editable label {
  background-image: radial-gradient(#fff27b 5%, #e4b20a 60%);
  cursor: pointer;
}

.readonly-star {
  width: 48px;
  height: 48px;
  padding: 48px 0 0;
  overflow: hidden;
  background-color: $amber;
  -webkit-mask: url('../icons/star-solid.svg') no-repeat 50% 50%;
  mask: url('../icons/star-solid.svg') no-repeat 50% 50%;
  &--unfilled {
    -webkit-mask: url('../icons/star-regular.svg') no-repeat 50% 50%;
    mask: url('../icons/star-regular.svg') no-repeat 50% 50%;
  }
}

.stars.vertical .readonly-star {
  width: 30px;
  height: 30px;
}

// any stars without solid class are displayed as empty
.stars:not(:hover) .star__input-wrapper:not(.solid) label {
  -webkit-mask: url('../icons/star-regular.svg') no-repeat 50% 50%;
  mask: url('../icons/star-regular.svg') no-repeat 50% 50%;
}

// hover over empty stars WITHOUT focus
.stars:hover .star__input-wrapper:not(.solid).editable:hover label {
  -webkit-mask: url('../icons/star-solid.svg') no-repeat 50% 50%;
  mask: url('../icons/star-solid.svg') no-repeat 50% 50%;
}

// hover over empty stars WITH focus eg user has just selected this star
.stars:hover .star__input-wrapper:not(.solid).editable:hover input:focus + label {
  -webkit-mask: url('../icons/star-regular.svg') no-repeat 50% 50%;
  mask: url('../icons/star-regular.svg') no-repeat 50% 50%;
}

// hover over empty stars - stars to the right is still empty
.stars:hover
  .star__input-wrapper:not(.solid).editable:hover
  + .star__input-wrapper
  label,
.stars:hover
  .star__input-wrapper:not(.solid).editable:hover
  + .star__input-wrapper
  + .star__input-wrapper
  label {
  -webkit-mask: url('../icons/star-regular.svg') no-repeat 50% 50%;
  mask: url('../icons/star-regular.svg') no-repeat 50% 50%;
}

// hover over full stars WITH focus - no change as user has probably just clicked this star
.stars:hover .star__input-wrapper.solid.editable:hover input:focus + label {
  -webkit-mask: url('../icons/star-solid.svg') no-repeat 50% 50%;
  mask: url('../icons/star-solid.svg') no-repeat 50% 50%;
}

// hover over full stars - stars to the right are empty
.stars:hover .star__input-wrapper.solid.editable:hover + .star__input-wrapper label,
.stars:hover
  .star__input-wrapper.solid.editable:hover
  + .star__input-wrapper
  + .star__input-wrapper
  label {
  -webkit-mask: url('../icons/star-regular.svg') no-repeat 50% 50%;
  mask: url('../icons/star-regular.svg') no-repeat 50% 50%;
}

.list-item {
  display: flex;
  flex-direction: row;

  &-snake {
    display: flex;
    flex-direction: column;
    transform: translateY(20px);
  }

  &-image {
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    border: 3px solid $primary;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-line {
    position: relative;
    height: 100%;
    flex: 1 1 auto;

    &:before {
      content: '';
      width: 3px;
      height: 100%;
      background-color: $primary;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    &--black:before {
      background-color: black;
    }
  }

  &-other {
    padding: 1.25rem;
    border-bottom: 1px solid gray;
  }
}

.snake-ol {
  --bs-accordion-btn-padding-y: 0px;
}

.snake-ol {
  --bs-accordion-body-padding-y: 0px;
}

.snake-ol {
  --bs-accordion-btn-bg: none;
}

.snake-ol {
  --bs-accordion-active-bg: white;
}

.snake-ol {
  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    color: #212529;
  }

  .accordion-button {
    padding: 0px;
  }

  .accordion-button:not(.collapsed) {
    background: none;
  }
}

.empty-objective {
  .accordion-button::after {
    background-image: none;
  }
  button:not(:disabled),
  [type='button']:not(:disabled) {
    cursor: default;
  }
}

.resource-video {
  iframe {
    height: 140px;
    width: 230px;
  }
}

.resources-accordion {
  border: 0px;

  .snake-header {
    border: 0px;
    padding-left: 0px;
    padding-top: 0px;

    .list-item-other {
      padding-bottom: 0px;
    }
  }

  .snake-body {
    margin-left: 80px;
    padding-left: 0px;
    border-bottom: 1px solid gray;

    .accordion-collapse {
      margin-left: 0px;
    }
  }

  .list-item-other {
    border-bottom: none;
  }
}

.snake-ol {
  position: relative;

  .list-item-image {
    background-color: white;
  }

  .card:not(:last-child) {
    .accordion-line {
      position: absolute;
      height: 100%;
      left: 30px;
      top: 30px;
      flex: 1 1 auto;
      z-index: 1;

      &:before {
        content: '';
        width: 3px;
        height: 100%;
        background-color: $primary;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  
}

.list-item-snake {
  z-index: 2;
}

@include media-breakpoint-up(md) {
  .resource-video {
    iframe {
      height: 315px;
      width: 560px;
    }
  }
}
